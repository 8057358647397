import React from "react";
import { Form } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import moment from "moment";
import { dateTimeFormat_YYYYMMDD_HHmmss } from "../../constants/dateFormats";
import SelectYear from "./SelectYear";

const FormikYearPicker = ({ ...props }: any) => {
  const formikProps = useFormikContext<any>();
  const [field] = useField(props);
  const firstValidYear = Number(moment().format("YYYY"));
  const lastValidYear = 1920;

  const handleChange = async (newValue: any) => {
    const value = newValue.value;
    const date = props.type === "number" ? Number(value) : moment(value, "YYYY").format(dateTimeFormat_YYYYMMDD_HHmmss);
    await formikProps.setFieldTouched(field.name, true, false);
    formikProps.setFieldError(field.name, "");
    await formikProps.setFieldValue(field.name, value ? date : value);
  };

  const isInvalid = Boolean(!!formikProps.errors[field.name] && formikProps.touched[field.name]);

  return (
    <Form.Group className={"FormikYearPicker form-group"}>
      {props.title !== undefined ? <Form.Label>{props.title}</Form.Label> : null}
      <SelectYear
        onChange={(e) => handleChange(e)}
        value={moment(field.value).year().toString()}
        firstValidYear={firstValidYear}
        lastValidYear={lastValidYear}
        isInvalid={isInvalid}
        onBlur={() => formikProps.setFieldTouched(field.name, true)}
      />
      <Form.Control.Feedback type="invalid">{String(formikProps.errors[field.name])}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default FormikYearPicker;

import React, { useEffect, useRef } from "react";
import { Dropdown, DropdownButton, ListGroup } from "react-bootstrap";
import { IProspectFetch } from "../../contracts/data/IProspectFetch";

export type TTablePaginationProps = {
  updateTable: (type: any, newState: any) => void;
  payload: IProspectFetch;
  sizePerPageOptions: number[];
  totalSize: number;
};

const TablePagination = ({ updateTable, payload, sizePerPageOptions, totalSize }: TTablePaginationProps) => {
  const paginationRef = useRef<HTMLDivElement>(null);
  const pageCount = Math.ceil(totalSize / payload.sizePerPage);
  const pageNumbers = Array.from({ length: pageCount }, (_, i) => i + 1);

  const centerPageInScroll = (page: number) => {
    if (paginationRef.current) {
      const buttonWidth = 46;
      const containerWidth = paginationRef.current.offsetWidth;
      paginationRef.current.scrollLeft = buttonWidth * (page - 1) - containerWidth / 2 + buttonWidth / 2;
    }
  };

  useEffect(() => {
    centerPageInScroll(payload.page);
  }, [payload.page]);

  return (
    <div className="pagination">
      <DropdownButton
        title={payload.sizePerPage}
        onSelect={(e) => {
          updateTable("pagination", { ...payload, sizePerPage: Number(e), page: 1 });
        }}
      >
        {sizePerPageOptions.map((pageSize: number) => (
          <Dropdown.Item key={pageSize} eventKey={pageSize}>
            {pageSize}
          </Dropdown.Item>
        ))}
      </DropdownButton>
      <ListGroup horizontal className="justify-content-end" style={{ width: "95%" }}>
        {payload.page > 1 && (
          <ListGroup.Item onClick={() => updateTable("pagination", { ...payload, page: 1 })}>{"<<"}</ListGroup.Item>
        )}
        {payload.page > 1 && (
          <ListGroup.Item onClick={() => updateTable("pagination", { ...payload, page: payload.page - 1 })}>
            {"<"}
          </ListGroup.Item>
        )}
        <ListGroup horizontal className="overflow-auto" ref={paginationRef}>
          {pageNumbers.map((pg: number) => (
            <ListGroup.Item
              key={pg}
              active={payload.page === pg}
              onClick={() => updateTable("pagination", { ...payload, page: pg })}
            >
              {pg}
            </ListGroup.Item>
          ))}
        </ListGroup>
        {payload.page < pageCount && (
          <ListGroup.Item onClick={() => updateTable("pagination", { ...payload, page: payload.page + 1 })}>
            {">"}
          </ListGroup.Item>
        )}
        {payload.page < pageCount && (
          <ListGroup.Item onClick={() => updateTable("pagination", { ...payload, page: pageCount })}>
            {">>"}
          </ListGroup.Item>
        )}
      </ListGroup>
    </div>
  );
};

export default TablePagination;

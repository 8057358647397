import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { IProspect, MaritalStatus } from "../../../contracts/data/IProspect";
import { Formik } from "formik";
import { Button, Form, Spinner } from "react-bootstrap";
import AlertAutoDismissible from "../../shared/AlertAutoDismissible";
import * as Yup from "yup";
import { TUpdateProspectAction } from "../../../redux/actions/prospect";
import FormikDatePicker from "../../shared/FormikDatePicker";
import { NumericFormat } from "react-number-format";
import FormikInput from "../../shared/FormikInput";
import FormikRadioGroup, { TRadioGroupItem } from "../../shared/FormikRadioGroup";
import moment from "moment";
import { IChildDetail } from "../../../contracts/data/IChildDetail";
import FormikChildrenPicker from "../../shared/FormikChildrenPicker";
import FormikYelDiscountPicker from "../../shared/FormikYelDiscountPicker";
import { InsuranceType } from "../../../contracts/data/IInsurance";
import FormikYearPicker from "../../shared/FormikYearPicker";
import PromptMessage, { SetPromptMessageFunc } from "../../shared/PromptMessage";
import { hasFormikError } from "../../../utils/appUtils";
import SelectYear from "../../shared/SelectYear";
import AccumulatedPensionInput from "../../shared/AccumulatedPensionInput";

type TFormValues = {
  birthDate: string | null;
  annualIncomeYel: number | null;
  monthlyPension: number | null;
  maritalStatus: MaritalStatus | null;
  marriedYear: number | null;
  spouseBirthDate: string | null;
  childAmount: number | null;
  childDetails: IChildDetail[];
  spouseMonthlySalary: number | null;
  yelDiscount: number | null;
};

type TProps = {
  isSubmitting: boolean;
  prospect: IProspect;
  updateProspect: TUpdateProspectAction;
  setPromptMessage: SetPromptMessageFunc;
} & WithTranslation;

type TState = {
  submitResultMessage: string;
  submitResultSuccess: boolean;
};

const AnnualYelIncomeLowerLimit: number = 9209;
const AnnualYelIncomeUpperLimit: number = 209125;

const SpouseMaxAge: number = 85;

class CurrentSituationForm extends Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);

    this.state = {
      submitResultMessage: "",
      submitResultSuccess: true,
    };
  }

  render() {
    const { t, prospect } = this.props;

    const initialValues: TFormValues = {
      birthDate: prospect.birthDate,
      annualIncomeYel: prospect.annualIncomeYel,
      monthlyPension: prospect.monthlyPension,
      maritalStatus: prospect.maritalStatus,
      marriedYear: prospect.marriedYear,
      spouseBirthDate: prospect.spouseBirthDate,
      childAmount: prospect.childAmount,
      childDetails: prospect.childDetails,
      spouseMonthlySalary: prospect.spouseMonthlySalary,
      yelDiscount: prospect.yelDiscount,
    };

    if (initialValues.childDetails.length !== Number(initialValues.childAmount)) {
      initialValues.childDetails = [];
      for (let i = 0; i < Number(initialValues.childAmount); i++) {
        initialValues.childDetails.push({
          id: "",
          sortOrder: i,
          age: null,
        });
      }
    }

    const validationSchema = Yup.object().shape({
      birthDate: Yup.lazy((value) => {
        if (value !== null) {
          return Yup.date().required(t("Required"));
        }

        return Yup.mixed().required(t("Required"));
      }),
      annualIncomeYel: Yup.lazy((value) => {
        if (value !== null) {
          return Yup.number()
            .required(t("Required"))
            .min(AnnualYelIncomeLowerLimit, t("Value too low"))
            .max(AnnualYelIncomeUpperLimit, t("Value too high"));
        }
        return Yup.mixed().required(t("Required"));
      }),
      monthlyPension: Yup.lazy((value) => {
        if (value !== null) {
          return Yup.number().required(t("Required")).min(0, t("Must be positive"));
        }
        return Yup.mixed().required(t("Required"));
      }),
      maritalStatus: Yup.lazy((value) => {
        if (value !== null) {
          return Yup.number().required(t("Required"));
        }
        return Yup.mixed().required(t("Required"));
      }),
      marriedYear: Yup.mixed().when("maritalStatus", ([maritalStatus]) => {
        if (maritalStatus !== MaritalStatus.Single) {
          return Yup.lazy((value) => {
            if (value !== null) {
              return Yup.number()
                .required(t("Required"))
                .min(Number(moment().add(-100, "years").format("YYYY")), t("Invalid year"))
                .max(Number(moment().format("YYYY")), t("Invalid year"));
            }
            return Yup.mixed().required(t("Required"));
          });
        }
        return Yup.mixed().notRequired();
      }),

      childAmount: Yup.lazy((value) => {
        if (value !== null) {
          return Yup.number().required(t("Required")).min(0, t("Must be positive"));
        }
        return Yup.mixed().required(t("Required"));
      }),
      childDetails: Yup.array().of(
        Yup.object().shape({
          age: Yup.lazy((value) => {
            if (value !== null) {
              return Yup.number().required(t("Required")).min(0, t("Must be positive"));
            }
            return Yup.mixed().required(t("Required"));
          }),
        }),
      ),
      spouseMonthlySalary: Yup.mixed().when("maritalStatus", ([maritalStatus]) => {
        if (maritalStatus === MaritalStatus.Married || maritalStatus === MaritalStatus.RegisteredPartnership) {
          return Yup.lazy((value) => {
            if (value !== null && value !== undefined) {
              return Yup.number().required(t("Required")).min(0, t("Must be positive"));
            }
            return Yup.mixed().required(t("Required"));
          });
        }
        return Yup.mixed().notRequired();
      }),

      spouseBirthDate: Yup.mixed().when("maritalStatus", ([maritalStatus]) => {
        if (maritalStatus === MaritalStatus.Married || maritalStatus === MaritalStatus.RegisteredPartnership) {
          return Yup.lazy((value) => {
            if (value !== null && value !== undefined) {
              const minYear = moment(moment().year() - SpouseMaxAge, "YYYY");
              return Yup.date()
                .required(t("Required"))
                .min(new Date(minYear.toDate()), t("Invalid year") + ". Min: " + moment(minYear).format("YYYY"))
                .max(new Date(Date.now()), t("Invalid date"));
            }
            return Yup.date().required(t("Required"));
          });
        }
        return Yup.mixed().notRequired();
      }),

      yelDiscount: Yup.mixed().required(t("Required")),
    });

    const marriageStatusItems: TRadioGroupItem[] = [
      {
        label: t("marriage"),
        value: MaritalStatus.Married,
      },
      {
        label: t("registered partnership"),
        value: MaritalStatus.RegisteredPartnership,
      },
      {
        label: t("single"),
        value: MaritalStatus.Single,
      },
    ];

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          this.setState((state) => ({
            ...state,
            submitResultSuccess: true,
            submitResultMessage: "",
          }));

          prospect.insurances.forEach((insurance) => {
            if (insurance.type === InsuranceType.Current) {
              insurance.isVisible = true;
            }
          });

          if (values.maritalStatus === MaritalStatus.Single) {
            if (values.childAmount === 0) {
              values.spouseBirthDate = null;
            }
            values.spouseMonthlySalary = null;
            values.marriedYear = null;
          }

          const result = await this.props.updateProspect(prospect.id, { ...values });

          if (result !== null) {
            this.setState((state) => ({
              ...state,
              submitResultSuccess: true,
              submitResultMessage: t("Successfully saved"),
            }));
          } else {
            this.setState((state) => ({
              ...state,
              submitResultSuccess: false,
              submitResultMessage: t("Unable to save"),
            }));
          }
        }}
      >
        {(formikProps) => (
          <Form autoComplete={"off"} noValidate onSubmit={(e: any) => formikProps.handleSubmit(e)}>
            <Form.Group className="form-group">
              <Form.Label>{t("Birthdate")}</Form.Label>
              <FormikDatePicker name={"birthDate"} />
              <div className="d-flex justify-content-between">
                {prospect.age && (
                  <Form.Label>
                    <span>{t("Age")}: </span>
                    <NumericFormat value={prospect.age} displayType={"text"} decimalScale={2} decimalSeparator={","} />
                  </Form.Label>
                )}
                {prospect.minimumPensionAge && (
                  <Form.Label>
                    <span>{t("Minimum pension age")}: </span>
                    <NumericFormat
                      value={prospect.minimumPensionAge}
                      displayType={"text"}
                      decimalScale={2}
                      decimalSeparator={","}
                    />
                  </Form.Label>
                )}
              </div>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>{t("Current YEL")}</Form.Label>
              <FormikInput name={"annualIncomeYel"} type={"number"} unit={"€/" + t("year_unit")} />
            </Form.Group>
            <AccumulatedPensionInput />
            <Form.Group className="form-group">
              <FormikChildrenPicker name={"childAmount"} detailsName={"childDetails"} title={t("Number of children")} />
            </Form.Group>
            <Form.Group className="form-group">
              <FormikRadioGroup name={"maritalStatus"} items={marriageStatusItems} title={t("Marital status")} />
              {formikProps.values.maritalStatus !== MaritalStatus.Single ? (
                <Form.Group className={"FormikYearPicker form-group"}>
                  <Form.Label>
                    {formikProps.values.maritalStatus === MaritalStatus.Married
                      ? t("Year of marriage")
                      : t("Year of partnership registration")}
                  </Form.Label>
                  <SelectYear
                    onChange={async (newValue) => {
                      await formikProps.setFieldTouched("marriedYear", true, false);
                      formikProps.setFieldError("marriedYear", "");
                      await formikProps.setFieldValue("marriedYear", newValue?.value);
                    }}
                    value={formikProps.values.marriedYear?.toString() ?? ""}
                    firstValidYear={Number(moment().format("YYYY"))}
                    lastValidYear={1920}
                    isInvalid={!!formikProps.errors.marriedYear && formikProps.touched.marriedYear}
                    onBlur={() => formikProps.setFieldTouched("marriedYear", true)}
                  />
                  <Form.Control.Feedback type="invalid">{formikProps.errors.marriedYear}</Form.Control.Feedback>
                </Form.Group>
              ) : null}
              {formikProps.values.maritalStatus !== MaritalStatus.Single ? (
                <FormikYearPicker name={"spouseBirthDate"} title={t("Spouse birthyear")} />
              ) : null}
              {formikProps.values.maritalStatus !== MaritalStatus.Single ? (
                <FormikInput
                  name={"spouseMonthlySalary"}
                  type={"number"}
                  unit={"€/" + t("month_unit")}
                  title={t("Spouse average monthly salary")}
                />
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <FormikYelDiscountPicker name={"yelDiscount"} title={t("Start-up discount")} />
            </Form.Group>
            <Form.Group className="form-group">
              <Button variant="primary" type="submit" className={"w-100"} disabled={this.props.isSubmitting}>
                {this.props.isSubmitting ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{" "}
                  </>
                ) : null}
                {t("Show current situation")}
              </Button>
              {this.state.submitResultMessage ? (
                <AlertAutoDismissible
                  variant={this.state.submitResultSuccess ? "success" : "danger"}
                  className={"mt-2"}
                >
                  {this.state.submitResultMessage}
                </AlertAutoDismissible>
              ) : null}
            </Form.Group>
            <PromptMessage
              setPromptMessage={this.props.setPromptMessage}
              message={{
                place: t("Questions affecting current situation"),
                changes: hasFormikError(formikProps.errors) ? null : formikProps.values,
              }}
              enabled={formikProps.dirty}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withTranslation("translations")(CurrentSituationForm);

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  // we init with resources
  resources: {
    en: {
      translations: {
        "PDF disclaimer text":
          "The readings are indicative. All our calculations are based on information received from the customer. " +
          "Any changes in insurance policies or legislation may change the accuracy of the information.",
        "Insurance disclaimer text":
          "Tämän laskurin ilmoittama hinta on arvio eikä se sido vakuutuksenantajaa. " +
          "Vakuutustarjouksesta ilmenee vakuutusturvan lopullinen hinta. ",
        Login: "Login",
        Password: "Password",
        Email: "Email",
        "Create customer": "Create customer",
        Logout: "Logout",
        "Invalid email address": "Invalid email address",
        Required: "Required",
        "Login failed": "Login failed",
        "Confirm Password": "Confirm Password",
        "Passwords must match": "Passwords must match",
        "User registration successful": "User registration successful",
        "User registration failed": "User registration failed",
        Register: "Register user",
        Firstname: "Firstname",
        Lastname: "Lastname",
        "Social security number": "Social security number",
        StreetAddress: "Street address",
        Zipcode: "Zipcode",
        City: "City",
        "Country of birth": "Country of birth",
        Citizenship: "Citizenship",
        Language: "Language",
        "Registration code": "Registration code",
        Customers: "Customers",
        year_unit: "year",
        month_unit: "month",
        day_unit: "day",
        Income: "Income",
        GrossIncome: "Gross Income",
        "YEL income": "YEL income",
        "Current YEL": "Current YEL",
        "Accumulated pension to date": "Accumulated pension to date",
        "Accumulated pension": "Accumulated pension",
        Birthdate: "Birthdate",
        "Marital status": "Marital status",
        marriage: "Married",
        "registered partnership": "Registered partnership",
        single: "Single",
        Married: "Married",
        "Year of marriage": "Year of marriage",
        "Year of partnership registration": "Year of partnership registration",
        "Number of children": "Number of children",
        Yes: "Yes",
        No: "No",
        "Basic information": "Basic information",
        "Household information": "Household information",
        "Current insurance": "Current",
        "Comparison insurance": "Proposal 1",
        "Offer insurance": "Proposal 2",
        "Family pension": "Family pension",
        "Family pension one-off payment": "Life insurance",
        "Sickness benefit": "Sickness benefit",
        "Sickness benefit statutory": "Sickness benefit (Statutory)",
        "Disability pension": "Disability pension",
        "Disability pension statutory": "Disability pension (Statutory)",
        "Disability pension one-off payment": "Disability pension one-off payment",
        "Optional disability pension": "Optional disability pension",
        "Serious illness one-off payment": "Serious illness one-off payment",
        "Expected net rate": "Net rate",
        "Old age pension": "Old age pension",
        "Old age pension extra": "Extra pension",
        "Old age pension optional": "Extra pension (Optional)",
        "Common pension": "Common pension",
        "Work pension": "Work pension",
        "Warranty pension": "Warranty pension",
        "Annual cost": "Annual cost",
        "Monthly cost": "Monthly cost",
        "Your benefits here with earnings before taxes": "Your benefits here with earnings before taxes",
        Total: "Total",
        "Your benefits": "Your benefits",
        contains: "contains",
        "Total (one-off)": "Total (one-off)",
        Questions: "Questions",
        "Edit customer": "Edit customer",
        Notes: "Notes",
        "Family situation": "Family situation",
        "YEL information": "YEL information",
        "Other household information": "Other household information",
        "Optional insurances": "Optional insurances",
        Summary: "Summary",
        Save: "Save",
        Update: "Update",
        Create: "Create",
        "Invalid date": "Invalid date",
        "Invalid year": "Invalid year",
        "Successfully saved": "Successfully saved",
        "Unable to save": "Unable to save",
        "Successfully updated": "Successfully updated",
        "Unable to update": "Unable to update",
        "Successfully created": "Successfully created",
        "Unable to create": "Unable to create",
        January: "January",
        February: "February",
        March: "March",
        April: "April",
        May: "May",
        June: "June",
        July: "July",
        August: "August",
        September: "September",
        October: "October",
        November: "November",
        December: "December",
        "Partner salary": "Partner salary",
        Dividends: "Dividends",
        "Annual earnings": "Annual earnings",
        Assets: "Assets",
        "Average salary": "Average salary",
        "Less than four years in business": "Less than four years in business",
        Customer: "Customer",
        Search: "Search",
        "Name, company or Business ID": "Name, company or Business ID",
        Seller: "Seller",
        Meeting: "Meeting date and time",
        at_clock: " ",
        Visible: "Visible",
        "Totals visible": "Totals visible",
        "New customer": "Uusi asiakas",
        "The following message will be sent to the customer": "The following message will be sent to the customer",
        "Email has been sent to the customer": "Email has been sent to the customer.",
        "Unable to send email": "Unable to send email",
        Back: "Back",
        Hello: "Hello",
        Send: "Send",
        "When you have confirmed the information above, press Send.":
          "When you have confirmed the information above, press Send.",
        "Fill out one of these two": "Fill out one of these two",
        "To check your pension, visit this link": "To check your pension, visit this link",
        "with your bank codes.": "with your bank codes.",
        "Alternatively, you can enter an estimate of your average monthly salary.":
          "Alternatively, you can enter an estimate of your average monthly salary.",
        "Best regards": "Best regards",
        Thanks: "Thanks",
        "Has filled in basic information": "Customer has filled in basic information",
        "Must be positive": "Must be positive",
        "Password is too short": "Password is too short",
        "Password must contain at least one uppercase letter": "Password must contain at least one uppercase letter",
        "Password must contain at least one lowercase letter": "Password must contain at least one lowercase letter",
        "Password must contain at least one non alphanumeric": "Password must contain at least one non alphanumeric",
        ValidRegistrationCodeValidator_Message: "Invalid registration code",
        UserAlreadyExistException_Message: "User already exists",
        RefreshTokenExpiredException_Message: "Refresh token has expired",
        IsAllowedDomainValidator_Message: "Disallowed email domain name",
        UnknownError_Message: "Unknown error occurred",
        Partnership: "In partnership with",
        Age: "Age",
        "Company information": "Company information",
        "Company name": "Company name",
        "Broker": "Broker",
        "Company city": "Company city",
        Fiscal: "Fiscal",
        "Fiscal month": "Fiscal month",
        "Company address": "Company address",
        "Company phone": "Company phone",
        "Company ownership": "Company ownership",
        "Company type": "Company type",
        "Company id": "Business id",
        "Company name and type": "Company name and type",
        "Annual YEL information": "Annual YEL information",
        "Number of YEL years": "Number of YEL years",
        "Start-up discount": "Start-up discount",
        "Monthly salary of the insured": "Monthly salary of the insured",
        "Average annual income / annual YEL income during the last 5 years":
          "Average annual income / annual YEL income during the last 5 years",
        "Estimation of worth": "Estimation of worth",
        "Income requirement / month ": "Income requirement / month",
        Loans: "Loans",
        "Company loans": "Company loans",
        "Personal loans": "Personal loans",
        "Number of children and age of children": "Number of children and age of children",
        "Spouse monthly pension or average monthly salary": "Spouse monthly pension or average monthly salary",
        "Spouse average monthly salary": "Spouse average monthly salary",
        "Spouse monthly pension": "Spouse monthly pension",
        "Spouse birthday": "Spouse birthday",
        "Spouse birthyear": "Spouse birthyear",
        "Invalid age": "Invalid age",
        assets_below_100k: "below 100k€",
        assets_between_100k_500k: "100k€ - 500k€",
        assets_between_500k_2M: "500k€ - 2M€",
        assets_between_2M_10M: "2M€ - 10M€",
        assets_above_10M: "above 10M€",
        "Underage children": "Underage children",
        "Cannot be higher than 100": "Cannot be higher than 100",
        "Cannot be higher than 85": "Cannot be higher than 85",
        "Other company owners": "Other company owners",
        "Family widow pension": "Family widow pension",
        "Family widow child pension": "Family widow child pension",
        "Family child pension": "Family child pension",
        "Sickness optional benefit": "Sickness optional benefit",
        "Sickness optional compensation": "Sickness optional compensation",
        "Optional life insurance": "Optional life insurance",
        "Minimum pension age": "Minimum pension age",
        "Download PDF": "Download as PDF",
        "Company security": "AKTIA and VERITAS",
        "Old age pension one-off payment": "Old age extra pension",
        "Sickness benefit daily payment": "Extra sickness disability",
        "Value too low": "Value too low",
        "Value too high": "Value too high",
        "Amount until end of life": "Total amount is {{amount}} € until end of life",
        "Total until end of life": "Total until end of life",
        "Total until eol - description": "Loppuelämän kokonaissumma",
        "TOL Business Industry Code": "TOL Business Industry Code",
        "Number of employees": "Number of employees",
        "Business revenue": "Business revenue",
        "Business profit": "Business profit",
        "Invalid tol code": "Invalid TOL code",
        "Sales data": "Sales data",
        "Lead channel": "Lead channel",
        "Booking channel": "Booking service",
        "Aktia channel": "Aktia",
        "Veritas channel": "Veritas",
        "Returning client channel": "Own list, returning client",
        "Digital channel": "Digital Channels",
        "Customer reference channel": "Customer reference",
        "Jeppis channel": "Jeppis",
        "Booking date": "Booking date",
        _Meeting: "Meeting",
        Created: "Created",
        Outcome: "Outcome",
        "Meeting outcome": "Outcome of the meeting",
        "Sale outcome": "Sale",
        "New meeting outcome": "New meeting",
        "No sale outcome": "No follow-up",
        "Sales data not filled exit": "Sales data has not been filled completely. Exit?",
        "Go to prospect": "Go to prospect",
        "Payment interval": "Payment interval",
        "time/y": "time / year",
        "times/y": "times / year",
        "Questions affecting current situation": "Questions affecting current situation",
        "Questions affecting the deemed need": "Questions affecting the deemed need",
        "Background information": "Background information",
        "Show current situation": "Show current situation",
        "Show deemed need": "Show deemed need",
        "Alternative insurance": "Alternative Yel",
        "Quantity": "Pcs",
        Reload: "Reload",
        "Missing insurances": "Missing insurances",
        "Insurance not found": "Insurance not found",
        "delete-prospect": "Delete prospect",
        "Delete comment": "Delete comment",
        "remove-prospect-confirm-message": "Confirm, that you want to delete prospect and alla data associated!",
        "remove-prospect-comment-confirm-message": "Confirm, that you want to delete comment!",
        "copy to clipboard": "Copy to clipboard",
        "copy failed": "Copy failed",
        "AKTIA and VERITAS cost breakdown": "AKTIA and VERITAS cost breakdown",
        "Per year": "Per year",
        "Per month": "Per month",
        total: "Total",
        "Sales State": "Sales State",
        "SalesProspect": "Prospect",
        "Pending": "Pending",
        "Rejected": "Rejected",
        "No state assigned": "No state assigned",
        "Comments": "Comments",
        "New comment": "New comment",
        "wrote": " wrote",
        "History": "History",
        "Sales state changed": "Sales state changed",
        ". New sales state is ": ". New sales state is ",
        "Not set": "Not set.",
        "View all": "View all",
        All: "All",
        "My own": "My own",
        "Error fetching data": "Error fetching data!",
        "Filter seller": "Filter seller",
        "All sellers": "All sellers",
        "Assign new seller": "Assign new seller",
        "Seller information": "Seller information",
        "Seller changed": "Seller changed",
        "Previous seller": "Previous seller: ",
        "New seller": "New seller: ",
        "No customers found": "No customers found. Try to unset all filters or try again later.",
        Current: "Current: ",
        "Please confirm": "Please confirm",
        "apply-discount-header": "Apply discount",
        "apply-discount-text" : "Are you sure you want to apply the following discount?",
        "cancel-discount-header": "Cancel discount",
        "cancel-discount-text" : "Are you sure you want to cancel the following discount?",
        "Create new customer":"Create new customer",
        "unsaved changes": "You have unsaved changes in:\n{{where}}\n",
        "unsaved changes confirm leave": "$t(unsaved changes). Are you sure you want to leave?",
        "unsaved changes confirm save":"$t(unsaved changes). Do you want to save all changes? ",
        "Percent": "Percent",
        "Occupation of the employee": "Occupation of the employee",
        "Entrepreneurial profession": "Entrepreneurial profession",
        "Industrial classification":"Industrial classification",
        "Products": "Products",
        "Total payroll":"Total payroll",
        "Employers": "Employers",
        "Full Name": "Full Name",
        "Ssn": "Hetu",
        "Annual earning (amount)": "Annual earning (amount)",
        "Add Employer": "Add Employer",
        "omsen_product_name_211": "Statutory workers compensation",
        "omsen_product_name_219": "Employees group life insurance",
        "omsen_product_name_217": "Employees leisure accident insurance",
        "omsen_product_name_212": "Employers insurance",
        "Invoice receiver name": "Invoice receiver name",
        "Claim receiver name": "Claim receiver name",
        "Internal note": "Internal note",
        "Customer category": "Customer category",
        "Business ID": "Business ID",
        "Postal address": "Postal address",
        "Postal code": "Postal code",
        "Country": "Country",
        "Country Of Registration": "Country Of Registration",
        "Countries of taxation": "Countries of taxation",
        "Real Beneficiaries": "Real Beneficiaries",
        "Add real beneficiary": "Add real beneficiary",
        "Tax domicile": "Tax domicile",
        "Citizenships" : "Citizenships",
        "Political exposed person" : "Political exposed person",
        "Position in company" : "Position in company",
        "Share holding information" : "Share holding information",
        "Create insurance proposal": "Create insurance proposal",
        "Premium Calculation": "Premium Calculation",
        "Proposal": "Proposal",
        "Company contact": "Company contact",
        "Name": "Name",
        "Confirm insurance proposal": "Confirm insurance proposal",
        "Cancel": "Cancel",
        "Confirm": "Confirm",
        "confirm_omsen_insurance_proposal_text": "When you confirm you can't modify Ålands Försäkringar Ab data anymore!",
        "Mobile": "Matkapuhelinnumero",
        "Invalid phone number": "Virheellinen puhelinnumero",
        "is required": " vaaditaan",
        "Business ID/Ssn is required":"Y-tunnus/Hetu vaaditaan",
        "At least 1 citizenship is required":"Vähintään 1 kansalaisuus vaaditaan",
        "At least 1 taxation country is required":"Vähintään 1 verotusmaa vaaditaan",
        "omsen_start_date_required": "Start date is required",
        "omsen_start_date_tomorrow_or_future": "Start date must be tomorrow or in the future",
        "omsen_start_date": "Start date",
        "Additional insurance": "Additional insurance",
        "Today": "Today",
        "Kela": "Kela",
        "Aktia": "Aktia",
        "lump sum": "lump sum",
        "Search with business Id": "Search with business id",
        "Company exist": "Company exist",
        "Company not exist": "Company not exist",
        "At least one role is needed": "At least one role is needed",
        "Roles": "Roles",
        "Booker": "Booker",
        "Active": "Active",
        "Inactive": "Inactive",
        "Users": "Users",
        "user": "user",
        "Updating user failed": "Updating user failed",
        "User updated successful": "User updated successful",
        "Show Password": "Show Password",
        "Edit": "Edit",
        "Create user": "Create user",
        "Industry Code": "Industry Code",
        "Pension start age": "Pension start",
        "Pension end age": "Pension end",
        "optional": "optional",
        Company: "Company",
        Industry: "Industry",
        Phone: "Puhelin",
        BirthYear: "Birth year",
        "AKTIA personal guarantee": "AKTIA personal guarantee",
        month: "month",
        "Previous average salary": "Previous average salary",
        "Add default questions": "Add default questions"
      },
    },
    fi: {
      translations: {
        "PDF disclaimer text":
          "Lukemat ovat suuntaa-antavia. Kaikki laskelmamme perustuvat asiakkaalta saatuihin tietoihin. " +
          "Mahdolliset muutokset vakuutusehdoissa tai lainsäädännöissä voivat muuttaa tietojen oikeellisuutta.",
        "Insurance disclaimer text":
          "Tämän laskurin ilmoittama hinta on arvio eikä se sido vakuutuksenantajaa. " +
          "Vakuutustarjouksesta ilmenee vakuutusturvan lopullinen hinta. ",
        Login: "Kirjaudu sisään",
        Password: "Salasana",
        Email: "Sähköposti",
        "Create customer": "Uusi asiakas",
        Logout: "Kirjaudu ulos",
        "Invalid email address": "Virheellinen sähköpostiosoite",
        Required: "Pakollinen",
        "Login failed": "Kirjautuminen epäonnistui",
        "Confirm Password": "Vahvista salasana",
        "Passwords must match": "Salasanojen täytyy täsmätä",
        "User registration successful": "Käyttäjän rekisteröinti onnistui",
        "User registration failed": "Käyttäjän rekisteröinti epäonnistui",
        Register: "Rekisteröi käyttäjä",
        Firstname: "Etunimi",
        Lastname: "Sukunimi",
        "Social security number": "Henkilötunnus",
        Phonenumber: "Puhelinnumero",
        StreetAddress: "Osoite",
        Zipcode: "Postinumero",
        City: "Kaupunki",
        "Country of birth": "Syntymämaa",
        Citizenship: "Kansalaisuus",
        Language: "Kieli",
        "Registration code": "Rekisteröintikoodi",
        Customers: "Asiakkaat",
        year_unit: "vuosi",
        month_unit: "kk",
        day_unit: "pv",
        Income: "Tulot",
        GrossIncome: "Bruttoansiotulot",
        "YEL income": "YEL-työtulo",
        "Current YEL": "Nykyinen YEL",
        "Accumulated pension to date": "Tähän mennessä kertynyt eläke",
        "Accumulated pension": "Eläkekertymä",
        Birthdate: "Syntymäaika",
        "Marital status": "Siviilisääty",
        marriage: "Naimisissa",
        "registered partnership": "Avoliitossa",
        single: "Asun yksin",
        Married: "Naimisissa",
        "Year of marriage": "Vuonna naimisiin",
        "Year of partnership registration": "Vuonna yhteistalouteen",
        "Number of children": "Lasten lukumäärä",
        Yes: "Kyllä",
        No: "Ei",
        "Basic information": "Perustiedot",
        "Household information": "Taloustiedot",
        "Current insurance": "Nykytilanne",
        "Comparison insurance": "Suositus",
        "Offer insurance": "Ehdotus",
        "Family pension": "Perhe-eläke",
        "Family pension one-off payment": "Henkivakuutus",
        "Sickness benefit statutory": "Sairauspäiväraha (lakisääteinen)",
        "Sickness benefit": "Sairauspäiväraha",
        "Disability pension": "Työkyvyttömyyseläke",
        "Disability pension statutory": "Työkyvyttömyyseläke (lakisääteinen)",
        "Disability pension one-off payment": "Pysyvä työkyvyttömyys",
        "Permanent disability insurance": "Pys. työkyv. vak.",
        "Optional disability pension": "Pysyvä työkyvyttömyys",
        "Serious illness one-off payment": "Vakavan sairauden vakuutus",
        "Expected net rate": "Arvioitu tuotto",
        "Old age pension": "Vanhuuseläke",
        "Old age pension extra": "Lisäeläke",
        "Old age pension optional": "Lisäeläke (vapaaehtoinen)",
        "Common pension": "Kansaneläke",
        "Work pension": "Työeläke",
        "Warranty pension": "Takuueläke",
        "Annual cost": "Vuosimaksu",
        "Monthly cost": "Kuukausimaksu",
        "Yearly payment": "Maksu/v",
        "Saving time": "Säästöaika",
        "Total payments": "Maksut yhteensä",
        "Savings at the beginning of pension": "Säästö eläkkeen alkaessa",
        "Total pension": "Eläkkeet yhteensä",
        "Your benefits here with earnings before taxes": "Etuutesi täällä työtulolla ennen veroja",
        Total: "Yhteensä",
        "Your benefits": "Sinun etuutesi",
        contains: "koostuu",
        "Total (one-off)": "Yhteensä (kertamaksu)",
        Questions: "Kysymykset",
        "Edit customer": "Muokkaa asiakas",
        Notes: "Muistiinpanot",
        "Family situation": "Perhetilanne",
        "YEL information": "YEL-tiedot",
        "Other household information": "Muut taloustiedot",
        "Optional insurances": "Valinnaiset vakuutukset",
        Summary: "Yhteenveto",
        Save: "Tallenna",
        Update: "Päivitä",
        Create: "Luo",
        "Invalid date": "Virheellinen päivämäärä",
        "Invalid year": "Virheellinen vuosi",
        "Successfully saved": "Tallennetut",
        "Successfully saved comment": "Tallennettu",
        "Unable to save": "Tallentaminen ei onnistu",
        "Successfully updated": "Päivitetty",
        "Unable to update": "Päivitys ei onnistu",
        "Successfully created": "Luotu onnistuneesti",
        "Unable to create": "Luotu ei onnistu",
        January: "Tammikuu",
        February: "Helmikuu",
        March: "Maaliskuu",
        April: "Huhtikuu",
        May: "Toukokuu",
        June: "Kesäkuu",
        July: "Heinäkuu",
        August: "Elokuu",
        September: "Syyskuu",
        October: "Lokakuu",
        November: "Marraskuu",
        December: "Joulukuu",
        "Partner salary": "Puolison palkka",
        Dividends: "Osingot",
        "Annual earnings": "Vuosiansiot",
        Assets: "Omaisuus",
        "Average salary": "Keskipalkka",
        "Less than four years in business": "Yritystoimintani on jatkunut alle neljä vuotta",
        Customer: "Asiakas",
        Search: "Hae",
        "Name, company or Business ID": "Nimi, yritys tai Y-tunnus ",
        Seller: "Myyjä",
        Meeting: "Kokouspäivämäärä",
        at_clock: " klo ",
        Visible: "Näkyvä",
        "Totals visible": "Näytä summat",
        "New customer": "Uusi asiakas",
        "The following message will be sent to the customer": "Seuraava viesti lähetetään asiakkaalle",
        "Email has been sent to the customer": "Sähköpostiviesti on lähetetty asiakkaalle.",
        "Unable to send email": "Sähköpostia ei voitu lähettää",
        Back: "Takaisin",
        Hello: "Hei",
        Send: "Lähetä",
        "When you have confirmed the information above, press Send.": "Kun olet tarkistanut tiedot, paina Lähetä.",
        "Fill out one of these two": "Täytä yksi näistä kahdesta",
        "To check your pension, visit this link": "Voit tarkistaa eläkekertymäsi tästä",
        "check-pension-link":"Voit tarkistaa eläkekertymäsi ",
        "check-pension-link-last-word": "tästä",
        "with your bank codes.": "",
        "Alternatively, you can enter an estimate of your average monthly salary.":
          "Voit vaihtoehtoisesti ilmoittaa meille arvion kuukausittaisesta keskiansiosta.",
        "Best regards": "Terveisin",
        Thanks: "Kiitoksia",
        "Has filled in basic information": "Customer has filled in basic information",
        "Must be positive": "Täytyy olla positiivinen",
        "Password is too short": "Password is too short",
        "More than 0": "Täytyy olla vähintään 1",
        "Less than 13": "Voi olla enintään 12",
        "Password must contain at least one uppercase letter": "Password must contain at least one uppercase letter",
        "Password must contain at least one lowercase letter": "Password must contain at least one lowercase letter",
        "Password must contain at least one non alphanumeric": "Password must contain at least one non alphanumeric",
        ValidRegistrationCodeValidator_Message: "Invalid registration code",
        UserAlreadyExistException_Message: "User already exists",
        RefreshTokenExpiredException_Message: "Refresh token has expired",
        IsAllowedDomainValidator_Message: "Disallowed email domain name",
        UnknownError_Message: "Unknown error occurred",
        Partnership: "Yhteistyössä",
        Age: "Ikä",
        "Company information": "Yhtiöntiedot",
        "Company name": "Yrityksen nimi",
        "Broker": "Meklari",
        "Company city": "Yrityksen kaupunki",
        "Company zipcode": "Yrityksen postinumero",
        "Company id": "Y-tunnus",
        "Company email": "Yrityksen sähköpostiosoite",
        "Company contact person": "Yrityksen yhteyshenkilö",
        "Company language": "Yrityksen kieli",
        Fiscal: "Fiskaali",
        "Fiscal month": "Fiskaali kuukausi",
        "Company address": "Yrityksen osoite",
        "Company phone": "Yrityksen puhelinnumero",
        "Company ownership": "Yrityksen omistusosuus",
        "Company type": "Yrityksen yhtiömuoto",
        "Company name and type": "Yrityksen nimi ja yhtiömuoto",
        "Annual YEL information": "YELin vuositulo",
        "Number of YEL years": "Monta vuotta ollut YEL",
        "Start-up discount": "Aloittavan yrittäjän alennus",
        "Monthly salary of the insured": "Vakuutetun palkka",
        "Average annual income / annual YEL income during the last 5 years":
          "Edellisen 5 vuoden keskimääräinen palkka / YEL tulo",
        "Estimation of worth": "Vakuutetun työpanoksen arvo",
        "Income requirement / month": "Tarve kuukaudessa jos sairastut",
        Loans: "Lainat",
        "Company loans": "Yritys",
        "Personal loans": "Henkilökohtaiset lainat",
        "Number of children and age of children": "Lasten lukumäärä ja lasten iät",
        "Spouse monthly pension or average monthly salary": "Puolison eläkekertymä tai keskimääräinen palkka",
        "Spouse average monthly salary": "Puolison keskimääräinen palkka",
        "Spouse monthly pension": "Puolison eläkekertymä",
        "Spouse birthday": "Puolison syntymäaika",
        "Spouse birthyear": "Puolison syntymävuosi",
        "Invalid age": "Virheellinen ikä",
        "Invalid assets": "Virheellinen omaisuus",
        assets_below_100k: "alle 100k€",
        assets_between_100k_500k: "100k€ - 500k€",
        assets_between_500k_2M: "500k€ - 2M€",
        assets_between_2M_10M: "2M€ - 10M€",
        assets_above_10M: "yli 10M€",
        "Underage children": "Alaikäisiä lapsia",
        "Cannot be higher than 100": "Maksimi 100",
        "Cannot be higher than 85": "Maksimi 85",
        "Other company owners": "Yrityksen muut omistajat",
        "Family widow pension": "Leskeneläke",
        "Family widow pension until end": "Leskeneläke loppuelämäksi",
        "Family widow pension help text": "Leskeneläke on edunjättäjän eläkkeestä laskettu osuus, joka riippuu lapseneläkkeen saajien määrästä. Leskeneläke on vähentämätön, jos lesken huollettavana on lapseneläkkeeseen oikeutettuja lapsia.\n" +
          "\n" +
          "Leskeneläkkeeseen on oikeus edunjättäjän avio- tai avopuolisolla, joka täyttää tietyt ehdot. Avopuoliso voi saada leskeneläkettä vain, jos huollettavana on alaikäinen lapsi.",
        "Family child pension": "Lapseneläke",
        "Family child pension until end": "Lapseneläke loppuelämäksi",
        "Family child pension help text": "Lapseneläke on tasan kaikille lapseneläkkeeseen oikeutetuille lapsille jaettu osuus edunjättäjän eläkkeestä. Jos leskeneläkkeensaajaa ei ole, lapseneläke sisältää myös lesken laskennallisen osuuden.\n" +
          "\n" +
          "Lapseneläkkeeseen on oikeus edunjättäjän alle 20-vuotiailla lapsilla. Oikeus voi olla myös edunjättäjän kanssa avioliitossa olleen lesken lapsilla.",
        "Total family pension for widow and children": "Perhe-eläke yhteensä leskelle ja lapsille",
        "Sickness optional benefit": "Sairauspäiväraha (vapaaehtoinen)",
        "Sickness optional compensation": "Päiväkorvaus",
        "Sickness optional duration": "Sairasloman pituus",
        "Optional life insurance": "Henkivakuutus",
        "Minimum pension age": "Eläke- ja tavoiteikä",
        "Download PDF": "Tallenna PDF-tiedostona",
        "Company security": "AKTIA ja VERITAS",
        "Life insurance": "Henkivakuutus",
        "Life insurance until end": "Henkivakuutus loppuelämäksi",
        "Old age pension one-off payment": "Lisäeläke",
        "Sickness benefit daily payment": "Ohimenevä työkyvyttömyys",
        "Value too low": "Arvo liian alhainen",
        "Value too high": "Arvo liian korkea",
        "payment examples": "Korvausesimerkit",
        "9 days sick leave": "9 päivää",
        "21 days sick leave": "21 päivää",
        "90 days sick leave": "90 päivää",
        "365 days sick leave": "365 päivää",
        "day 1": "Maanantaina",
        "day 2": "Tiistaina",
        "day 3": "Keskiviikkona",
        "day 4": "Torstaina",
        "day 5": "Perjantaina",
        "day 6": "Lauantaina",
        "day 7": "Sunnuntaina",
        "Amount until end of life": "Loppuelämän kokonaissumma {{amount}} €",
        "Total until end of life": "Loppuelämän kokonaissumma",
        "Total until eol - description": "Loppuelämän kokonaissumma {{amount}} €",
        "TOL Business Industry Code": "Yrityksen toimialakoodi TOL",
        "Number of employees": "Työntekijöiden määrä",
        "Business revenue": "Yrityksen liikevaihto",
        "Business profit": "Yrityksen voitto",
        "Invalid tol code": "Virheellinen TOL-koodi",
        "Sales data": "Myynnin dataa",
        "Lead channel": "Liidikanava",
        "Booking channel": "Buukkaus palvelu",
        "Aktia channel": "Aktia",
        "Veritas channel": "Veritas",
        "Returning client channel": "Oma lista, vanha asiakas",
        "Digital channel": "Digitaaliset kanavat",
        "Customer reference channel": "Asiakasreferenssi",
        "Jeppis channel": "Jeppis",
        "Booking date": "Buukkauksen pvm",
        _Meeting: "Kokous",
        Created: "Luotu",
        Outcome: "Tulos",
        "Meeting outcome": "Kokouksen tulos",
        "Sale outcome": "Kauppa",
        "New meeting outcome": "Uusi kokous",
        "No sale outcome": "Ei jatkoa",
        "Sales data not filled exit": "Myynnin dataa ei ole täytetty kokonaan. Poistu?",
        "Go to prospect": "Siirry lomakkeeseen",
        "Payment interval": "Maksuväli",
        "time/y": "kerta / vuosi",
        "times/y": "kertaa / vuosi",
        "Questions affecting current situation": "Nykytilanteeseen vaikuttavat kysymykset",
        "Questions affecting the deemed need": "Tarpeeseen vaikuttavat kysymykset",
        "Background information": "Taustatiedot",
        "Show current situation": "Katso nykytilanne",
        "Show deemed need": "Katso tarve",
        "Alternative insurance": "Vaihtoehtoinen Yel",
        "Quantity": "Kpl",
        Reload: "Lataa uudelleen",
        "Missing insurances": "Puuttuvat vakuutukset",
        "Insurance not found": "Vakuutus puuttuu",
        "delete-prospect": "Poista henkilö",
        "Delete comment": "Poista kommentti",
        "remove-prospect-confirm-message":
          "Oletko varma, että haluat poistaa yllämainitun henkilön ja kaiken häneen liittyvän tiedon!",
        "remove-prospect-comment-confirm-message": "Oletko varma, että haluat poistaa kommentin!",
        "copy to clipboard": "Kopioi tiedot",
        "copy failed": "Kopiointi epäonnistui",
        "AKTIA and VERITAS cost breakdown": "AKTIA ja VERITAS maksuerittely",
        "Per year": "Per vuosi",
        "Per month": "Per kuukausi",
        total: "Yhteensä",
        "Sales State": "Tilanne",
        "SalesProspect": "Prospekti",
        "Pending": "Kesken",
        "Rejected": "Hylätty",
        "No state assigned": "Tilannetta ei asetettu",
        "Comments": "Kommentit",
        "New comment": "Uusi kommentti",
        "wrote": " kirjoitti",
        "History": "Historia",
        "Sales state changed": "Tilanne vaihdettu.",
        "Previous state": "Tilanteesta: ",
        "New state": "Tilanteeseen: ",
        "Previous seller: ": "Vanha myyjä: ",
        "New seller: ": "Uusi myyjä: ",
        "Not set": "Tyhjä",
        "View all": "Näytä kaikki",
        All: "Kaikki",
        "My own": "Omat",
        "Error fetching data": "Dataa ei pystynyt hakea!",
        "Filter seller": "Suodata myyjän mukaan",
        "All sellers": "Kaikki myyjät",
        "Assign new seller": "Määritä uusi myyjä",
        "Seller information": "Myyjän tiedot",
        "Seller changed": "Myyjä vaihdettu",
        "Previous seller": "Myyjästä: ",
        "New seller": "Myyjään: ",
        "No customers found": "Asiakkaita ei löytynyt. Poista kaikki suodattimet tai yritä myöhemmin uudestaan.",
        Current: "Nykyinen: ",
        "Please confirm": "Vahvista",
        discount: "alennus",
        "No discount": "Ei alennusta",
        "Create new customer": "Luo uusi asiakas",
        "unsaved changes": "Sinulla on tallentamattomia muutoksia kohdissa:\n{{where}}\n",
        "unsaved changes confirm leave": "$t(unsaved changes). Haluatko varmasti poistua?",
        "unsaved changes confirm save":"$t(unsaved changes). Haluatko tallentaa kaikki muutokset? ",
        "Percent": "Prosentti",
        "Occupation of the employee": "Työntekijän ammatti",
        "Entrepreneurial profession": "Yrittäjän ammatti",
        "Industrial classification": "Toimialaluokitus",
        "Products": "Tuotteet",
        "Total payroll": "Palkkasumma",
        "Employers": "Yrittäjän tiedot",
        "Full Name": "Koko nimi",
        "Ssn": "Sotu",
        "Annual earning (amount)": "Vuositulo (summa)",
        "Add Employer": "Lisää yrittäjä",
        "omsen_product_name_211": "Lakisääteinen tapaturmavakuutus",
        "omsen_product_name_219": "Ryhmähenkivakuutus",
        "omsen_product_name_217": "Vapaa-ajan ryhmätapaturmavakuutus",
        "omsen_product_name_212": "Yrittäjän tapaturmavakuutus",
        "Invoice receiver name": "Laskun vastaanottajan nimi",
        "Claim receiver name": "Hakemuksen vastaanottajan nimi",
        "Internal note": "Muistiinpano",
        "Customer category": "Asiakastyyppi",
        "Business ID": "Y-tunnus",
        "Postal address": "Postiosoite",
        "Postal code": "Postinumero",
        "Country": "Maa",
        "Country Of Registration": "Rekisteröintimaa",
        "Countries of taxation": "Verotusmaat",
        "Real Beneficiaries": "Todelliset edunsaajat",
        "Add real beneficiary": "Lisää edunsaaja",
        "Tax domicile": "Verotus alue",
        "Citizenships" : "Kansalaisuudet",
        "Political exposed person" : "Poliittinen henkilö",
        "Position in company" : "Asema yrityksessä",
        "Share holding information" : "Omistusosuus",
        "Create insurance proposal": "Luo vakuutusehdotus",
        "Premium Calculation": "Premium-laskenta",
        "Proposal": "Ehdotus",
        "Company contact": "Yrityksen yhteystiedot",
        "Name": "Nimi",
        "Confirm insurance proposal": "Vahvista vakuutusehdotus",
        "Cancel": "Peruta",
        "Confirm": "Vahvista",
        "confirm_omsen_insurance_proposal_text": "Kun vahvistat, et voi enää muokata Ålands Försäkringar Ab-tietoja!",
        "omsen_start_date_required": "Aloituspäivä vaaditaan",
        "omsen_start_date_tomorrow_or_future": "Aloituspäivä on oltava huomenna tai myöhemmin",
        "omsen_start_date": "Aloituspäivä",
        "Additional insurance": "Lisäturva",
        "Today": "Tänään",
        "Kela": "Kela",
        "Aktia": "Aktia",
        "lump sum": "kertakorvaus",
        "Search with business Id": "Hae Y-tunnuksella",
        "Company exist": "Yritys on olemassa",
        "Company not exist": "Yritys ei löytynyt",
        "At least one role is needed": "Ainakin yksi rooli tarvitaan",
        "Roles": "Roolit",
        "Booker": "Ajanvaraaja",
        "Active": "Aktiivinen",
        "Inactive": "Lukittu",
        "Users": "Käyttäjät",
        "user": "käyttäjä",
        "Updating user failed": "Käyttäjän muokkaus epäonnistui",
        "User updated successful": "Käyttäjän muokkaus onnistui",
        "Show Password": "Näytä salasana",
        "Edit": "Muokkaa",
        "Create user": "Luo käyttäjä",
        "Industry Code": "Toimialakoodi",
        "Pension start age": "Eläke alkaa",
        "Pension end age": "Eläke päättyy",
        "optional": "vapaaehtoinen",
        Company: "Yritys",
        Industry: "Toimiala",
        Phone: "Puhelin",
        BirthYear: "Syntymävuosi",
        "AKTIA personal guarantee": "AKTIA henkilöturva",
        month: "kuukausi",
        "Previous average salary": "Aiempi keskipalkka",
        "Add default questions": "Lisää oletuskysymykset"
      },
    },
  },
  lng: "fi",
  fallbackLng: "en",
  debug: false,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },

  returnNull: false,
});

export default i18n;

import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { ErrorMessage, useField, useFormikContext } from "formik";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { dateFormat_YYYYMMMDD, dateTimeFormat_YYYYMMDD_HHmmss } from "../../constants/dateFormats";
import Select from "react-select";
import { selectCustomStyle } from "../../styles/selectCustomStyle";
import { ISelectOptionType } from "../../contracts/data/ISelectOptionType";
import SelectYear from "./SelectYear";

type TDateParts = {
  day: string;
  month: string;
  year: string;
};

const getMomentFromDateParts = (dateParts: TDateParts) => {
  return moment(dateParts.year + "-" + dateParts.month + "-" + dateParts.day, dateFormat_YYYYMMMDD, true);
};

const getMomentFromDateString = (date: string) => {
  return moment(date, dateTimeFormat_YYYYMMDD_HHmmss, true);
};

const getPartialDate = (date: string, part: "day" | "month" | "year") => {
  const parsedDate = getMomentFromDateString(date);
  if (parsedDate.isValid()) {
    switch (part) {
      case "day":
        return parsedDate.format("DD");
      case "month":
        return parsedDate.format("MM");
      case "year":
        return parsedDate.format("YYYY");
    }
  }

  return "";
};

const FormikDatePicker = ({ ...props }: any) => {
  const { t } = props;
  const formikProps = useFormikContext<any>();
  const [field] = useField(props);
  const [dateParts, setDateParts] = useState<TDateParts>({
    day: "",
    month: "",
    year: "",
  });
  const firstValidYear = props.firstValidYear !== undefined ? props.firstValidYear : 1920;
  const lastValidYear = props.lastValidYear !== undefined ? props.lastValidYear : Number(moment().format("YYYY"));

  const validMonths: ISelectOptionType[] = [
    { value: "01", label: t("January") },
    { value: "02", label: t("February") },
    { value: "03", label: t("March") },
    { value: "04", label: t("April") },
    { value: "05", label: t("May") },
    { value: "06", label: t("June") },
    { value: "07", label: t("July") },
    { value: "08", label: t("August") },
    { value: "09", label: t("September") },
    { value: "10", label: t("October") },
    { value: "11", label: t("November") },
    { value: "12", label: t("December") },
  ];

  const validDays: ISelectOptionType[] = Array.from({ length: 31 }, (_, i) => ({
    value: String(i + 1).padStart(2, "0"),
    label: String(i + 1).padStart(2, "0"),
  }));

  const handleChange = async (newValue: ISelectOptionType, part: "day" | "month" | "year") => {
    const value = newValue.value;
    const updateDateParts = {
      ...dateParts,
      [part]: value,
    };

    setDateParts(updateDateParts);

    const newDate = getMomentFromDateParts(updateDateParts);
    if (newDate.isValid()) {
      await formikProps.setFieldTouched(field.name, true, false);
      formikProps.setFieldError(field.name, "");
      await formikProps.setFieldValue(field.name, newDate.format(dateTimeFormat_YYYYMMDD_HHmmss));
    } else {
      await formikProps.setFieldTouched(field.name, true, false);
      formikProps.setFieldError(field.name, t("Invalid date"));
    }
  };

  const isCurrentDateInvalid = Boolean(
    !!formikProps.getFieldMeta(field.name).error && formikProps.getFieldMeta(field.name).touched,
  );
  const isSingleRow = props.singleRow !== undefined ? props.singleRow : false;

  const dayFormField = (
    <Select
      options={validDays}
      value={validDays.find((day) => day.value === dateParts.day)}
      styles={selectCustomStyle(isCurrentDateInvalid)}
      className={isCurrentDateInvalid ? "is-invalid" : "is-valid"}
      isDisabled={props.disabled}
      onChange={async (newValue) => newValue && (await handleChange(newValue, "day"))}
      placeholder=""
    />
  );

  const monthFormField = (
    <Select
      options={validMonths}
      value={validMonths.find((month) => month.value === dateParts.month)}
      styles={selectCustomStyle(isCurrentDateInvalid)}
      className={isCurrentDateInvalid ? "is-invalid" : "is-valid"}
      isDisabled={props.disabled}
      onChange={async (newValue) => newValue && (await handleChange(newValue, "month"))}
      placeholder=""
    />
  );

  const yearFormField = (
    <SelectYear
      onChange={async (newValue) => newValue && (await handleChange(newValue, "year"))}
      value={dateParts.year}
      firstValidYear={firstValidYear}
      lastValidYear={lastValidYear}
      isInvalid={isCurrentDateInvalid}
      isDisabled={props.disabled}
      optionToScroll={1990}
    />
  );

  useEffect(() => {
    setDateParts({
      day: getPartialDate(field.value, "day"),
      month: getPartialDate(field.value, "month"),
      year: getPartialDate(field.value, "year"),
    });
    return () => {
      setDateParts({
        day: "",
        month: "",
        year: "",
      });
    };
  }, [field.value]);

  return (
    <div className={"FormikDatePicker"}>
      {props.title !== undefined ? (
        <Row>
          <Form.Group as={Col} className={"mb-0 form-group"}>
            <Form.Label>{props.title}</Form.Label>
          </Form.Group>
        </Row>
      ) : null}
      {!isSingleRow ? (
        <>
          <Row>
            <Form.Group as={Col} xs={4} className={"mb-0 form-group"}>
              {dayFormField}
            </Form.Group>
            <Form.Group as={Col} xs={8} className={"mb-0 form-group"}>
              {monthFormField}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} className={"mt-2 form-group"}>
              {yearFormField}
              {props.nested ? (
                <ErrorMessage name={field.name} component={"div"} className="custom-invalid-feedback" />
              ) : (
                <Form.Control.Feedback type="invalid">
                  {String(formikProps.getFieldMeta(field.name).error)}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Row>
        </>
      ) : (
        <Row className={"mb-2"}>
          <Form.Group as={Col} xs={4} sm={3} md={4} lg={3} className={"mt-2 mb-1 form-group"}>
            {dayFormField}
          </Form.Group>
          <Form.Group as={Col} xs={8} sm={6} md={8} lg={6} className={"mt-2 mb-1 form-group"}>
            {monthFormField}
          </Form.Group>
          <Form.Group
            as={Col}
            xs={12}
            sm={3}
            md={12}
            lg={3}
            className={"mt-2 mb-1 form-group" + (isCurrentDateInvalid ? " is-invalid" : "")}
          >
            {yearFormField}
          </Form.Group>
          {props.nested ? (
            <ErrorMessage name={props.name} component={Form.Control.Feedback} className="custom-invalid-feedback" />
          ) : (
            <Form.Control.Feedback type="invalid">
              {String(formikProps.getFieldMeta(field.name).error)}
            </Form.Control.Feedback>
          )}
        </Row>
      )}
    </div>
  );
};

export default withTranslation("translations")(FormikDatePicker);

import React, { useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useField, useFormikContext } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import { calculateAge } from "../../utils/appUtils";

const AccumulatedPensionInput: React.FC<WithTranslation> = ({ t }) => {
  const name = "monthlyPension";
  const formikProps = useFormikContext<any>();
  const [field] = useField(name);
  const unit = "€/" + t("month_unit");
  const isInvalid = Boolean(!!formikProps.errors[field.name] && formikProps.touched[field.name]);
  const age = calculateAge(formikProps.values.birthDate);
  const previousAverageSalaryStartAge = 23;

  const calculatePreviousSalary = (monthlyPension: number) => {
    const value = Math.round(monthlyPension / (age - previousAverageSalaryStartAge) / 0.015);
    return value <= 0 ? "" : value.toString();
  };

  const calculateAccumulatedPension = (previousAverageSalary: number) => {
    const value = Math.round(previousAverageSalary * 0.015 * (age - previousAverageSalaryStartAge));
    return value <= 0 ? "" : value.toString();
  };

  const [previousAverageSalary, setPreviousAverageSalary] = useState<string>(
    calculatePreviousSalary(formikProps.values.monthlyPension),
  );

  return (
    <Form.Group className={"form-group"}>
      <Row className={isInvalid ? " is-invalid" : ""}>
        <Col>
          <Form.Label>{t("Accumulated pension")}</Form.Label>
          <div className={"input-group"}>
            <Form.Control
              name={field.name}
              type="number"
              onChange={async (event) => {
                formikProps.handleChange(event);
                setPreviousAverageSalary(calculatePreviousSalary(Number(event.target.value)));
              }}
              onBlur={formikProps.handleBlur}
              value={formikProps.values.monthlyPension}
              isInvalid={isInvalid}
              min={0}
            />
            <span className="input-group-text">{unit}</span>
          </div>
          <Form.Control.Feedback type="invalid">
            {String(formikProps.getFieldMeta(field.name).error)}
          </Form.Control.Feedback>
        </Col>
        {!!age && (
          <Col>
            <Form.Label>{t("Previous average salary")}</Form.Label>
            <div className={"input-group"}>
              <Form.Control
                type="number"
                value={previousAverageSalary}
                onChange={async (event) => {
                  const value = event.target.value;
                  setPreviousAverageSalary(value);
                  await formikProps.setFieldValue(name, calculateAccumulatedPension(Number(value)));
                }}
                isInvalid={isInvalid}
                min={0}
              />
              <span className="input-group-text">{unit}</span>
            </div>
          </Col>
        )}
      </Row>
      <Form.Control.Feedback type="invalid">{String(formikProps.getFieldMeta(field.name).error)}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default withTranslation("translations")(AccumulatedPensionInput);

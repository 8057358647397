import React from "react";
import { ISelectOptionType } from "../../contracts/data/ISelectOptionType";
import { selectCustomStyle } from "../../styles/selectCustomStyle";
import Select, { ActionMeta, SingleValue } from "react-select";

type Props = {
  onChange: (newValue: SingleValue<ISelectOptionType>, actionMeta: ActionMeta<ISelectOptionType>) => void;
  value: string;
  firstValidYear: number;
  lastValidYear: number;
  isInvalid?: boolean;
  isDisabled?: boolean;
  isClearable?: boolean;
  optionToScroll?: number;
  controllerStyle?: React.CSSProperties;
  onBlur?: () => void;
};

const SelectYear: React.FC<Props> = ({
  onChange,
  value,
  firstValidYear,
  lastValidYear,
  isInvalid = false,
  isDisabled = false,
  isClearable = false,
  optionToScroll,
  controllerStyle,
  onBlur,
}) => {
  const validYears: ISelectOptionType[] = Array.from(
    { length: Math.abs(lastValidYear - firstValidYear) + 1 },
    (v, k) => ({
      value: `${firstValidYear < lastValidYear ? firstValidYear + k : firstValidYear - k}`,
      label: `${firstValidYear < lastValidYear ? firstValidYear + k : firstValidYear - k}`,
    }),
  );
  const selectedOption = validYears.find((year) => year.value === value);
  return (
    <Select
      options={validYears}
      value={selectedOption}
      styles={selectCustomStyle(isInvalid, controllerStyle)}
      className={isInvalid ? "is-invalid" : "is-valid"}
      isDisabled={isDisabled}
      onChange={onChange}
      onBlur={onBlur}
      placeholder=""
      name="year_selct"
      classNamePrefix="year_select"
      onMenuOpen={() => {
        if (!selectedOption && optionToScroll)
          setTimeout(() => {
            const selectedEl = document.getElementsByClassName("year_select__option")[optionToScroll - firstValidYear];
            if (selectedEl) {
              selectedEl.scrollIntoView({ behavior: "auto", block: "nearest" });
            }
          }, 10);
      }}
      isClearable={isClearable}
    />
  );
};

export default SelectYear;

import { CSSProperties } from "react";

export function selectCustomStyle(isInvalid: boolean, controllerStyle?: CSSProperties) {
  return {
    control: (style: any, state: any) => ({
      ...style,
      borderRadius: 0,
      fontSize: "1.25rem",
      fontWeight: 300,
      borderColor: "inherit",
      boxShadow: boxShadowStyle(isInvalid, state.isFocused),
      "&:hover": {
        borderColor: "inherit",
      },
      ...controllerStyle,
    }),
    menu: (styles: any) => ({
      ...styles,
      zIndex: 999,
    }),
    option: (styles: any) => ({
      ...styles,
      minHeight: 35,
    }),
  };
}

const boxShadowStyle = (isInvalid: boolean, isFocused: boolean) => {
  if (isFocused) {
    return isInvalid ? "0 0 0 0.25rem rgb(224, 82, 96, 0.25)" : "0 0 0 0.25rem rgba(38, 89, 89, 0.25)";
  }
  return "none";
};

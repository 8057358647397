import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { IProspect } from "../../contracts/data/IProspect";
import { Formik } from "formik";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import AlertAutoDismissible from "../shared/AlertAutoDismissible";
import * as Yup from "yup";
import { TUpdateProspectAction } from "../../redux/actions/prospect";
import PromptMessage, { SetPromptMessageFunc } from "../shared/PromptMessage";
import { hasFormikError } from "../../utils/appUtils";

type TProspectEditNoteFormValues = {
  notes: string;
};

type TProspectEditNoteFormProps = {
  isSubmitting: boolean;
  prospect: IProspect;
  updateProspect: TUpdateProspectAction;
  setPromptMessage: SetPromptMessageFunc;
} & WithTranslation;

type TProspectEditNoteFormState = {
  submitResultMessage: string;
  submitResultSuccess: boolean;
};

class ProspectEditNoteForm extends Component<TProspectEditNoteFormProps, TProspectEditNoteFormState> {
  constructor(props: TProspectEditNoteFormProps) {
    super(props);

    this.state = {
      submitResultMessage: "",
      submitResultSuccess: true,
    };
  }

  render() {
    const { t, prospect } = this.props;

    const initialValues: TProspectEditNoteFormValues = {
      notes: prospect.notes,
    };

    const validationSchema = Yup.object().shape({
      notes: Yup.mixed(),
    });

    const defaultNoteQuestions =
      "- onko lakimuutokset käyty läpi\n" +
      "- kerrotko lyhyesti yritystoiminnastasi\n" +
      "- miten olet päätynyt nykyiseen YELiin\n" +
      "- yrityksesi suurin riski\n" +
      "- onko työntekijöitä\n" +
      "- työpanoksesi arvo\n" +
      "- palkka tästä yrityksestä\n" +
      "- onko muita palkkatuloja tai MYEL\n" +
      "- onko osinko tai vuokratuloja\n" +
      "- yrityksen kiinteät kulut\n" +
      "- nykyiset yhtiöt\n" +
      "- nykyiset vakuutukset\n" +
      "- edunsaajat (yritys, omaiset)";

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          this.setState((state) => ({
            ...state,
            submitResultSuccess: true,
            submitResultMessage: "",
          }));

          const result = await this.props.updateProspect(prospect.id, { ...values });

          if (result !== null) {
            this.setState((state) => ({
              ...state,
              submitResultSuccess: true,
              submitResultMessage: t("Successfully saved"),
            }));
          } else {
            this.setState((state) => ({
              ...state,
              submitResultSuccess: false,
              submitResultMessage: t("Unable to save"),
            }));
          }
        }}
      >
        {(formikProps) => (
          <Form autoComplete={"off"} noValidate onSubmit={(e: any) => formikProps.handleSubmit(e)}>
            <Form.Group className="form-group">
              <Row>
                <Col xs={3} className="align-self-center">
                  <Form.Label>{t("Notes")}</Form.Label>
                </Col>
                {formikProps.values.notes.trim() === "" && (
                  <Col className="d-flex justify-content-end my-1">
                    <Button variant="primary" onClick={() => formikProps.setFieldValue("notes", defaultNoteQuestions)}>
                      {t("Add default questions")}
                    </Button>
                  </Col>
                )}
              </Row>
              <Form.Control
                name={"notes"}
                as={"textarea"}
                placeholder={""}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.notes}
                rows={15}
                isInvalid={!!formikProps.errors.notes && formikProps.touched.notes}
              />
              <Form.Control.Feedback type="invalid">{formikProps.errors.notes}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="form-group">
              <Button variant="primary" type="submit" className={"w-100"} disabled={this.props.isSubmitting}>
                {this.props.isSubmitting ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{" "}
                  </>
                ) : null}
                {t("Save")}
              </Button>
              {this.state.submitResultMessage ? (
                <AlertAutoDismissible
                  variant={this.state.submitResultSuccess ? "success" : "danger"}
                  className={"mt-2"}
                >
                  {this.state.submitResultMessage}
                </AlertAutoDismissible>
              ) : null}
            </Form.Group>
            <PromptMessage
              setPromptMessage={this.props.setPromptMessage}
              message={{ place: t("Notes"), changes: hasFormikError(formikProps.errors) ? null : formikProps.values }}
              enabled={formikProps.dirty}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withTranslation("translations")(ProspectEditNoteForm);
